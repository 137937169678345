import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { DEFAULT_VIEW_TYPE, getColorByBgColor } from '../../utils';
import Marquee from 'react-fast-marquee';
import { MARQUEE_ENABLED } from './custom';
import { useLongPress } from 'use-long-press';

export const Playlist = ({
    tracks,
    currentTrack,
    selectTrackIndex,
    viewType,
    swipeHandlers,
    isEmpty,
    playlistData,
    setModal,
}) => {
    const bindUseLongPress = useLongPress((event) => {
        setModal({
            modal: 'track',
            payload: {
                trackId: event.target?.parentNode.dataset?.trackId,
                trackIndex: event.target?.parentNode.dataset?.trackRow,
            },
        });
    });

    if (isEmpty) {
        return (
            <div className={`Playlist`}>
                <div className={`Playlist--empty`}>
                    No se encuentran canciones
                </div>
            </div>
        );
    }
    return (
        <div
            className={`Playlist Playlist--${viewType ?? DEFAULT_VIEW_TYPE}`}
            {...swipeHandlers}
        >
            {tracks
                .filter((t) => !t.isFolder)
                .map((song, index) => {
                    const isCurrentTrack =
                        currentTrack && currentTrack.id === song.id;
                    const rowClass = isCurrentTrack
                        ? 'Playlist__item Playlist__item--active'
                        : 'Playlist__item';

                    const bgColor = isCurrentTrack
                        ? (playlistData.theme ?? '#024263')
                        : 'transparent';
                    const textColor = getColorByBgColor(bgColor);

                    return (
                        <div
                            className={rowClass}
                            style={{
                                backgroundColor: bgColor,
                                color: textColor,
                            }}
                            data-track-row={index}
                            data-track-id={song.id}
                            onClick={() => selectTrackIndex(index)}
                            key={song.id}
                            {...bindUseLongPress()}
                        >
                            <div className={'Playlist__item__over'}></div>
                            <div className="Playlist__item__cover">
                                <LazyLoadImage
                                    src={song?.properties?.cover ?? song.img}
                                    alt={''}
                                    placeholderSrc={'/server/default_cover.jpg'}
                                    placeholder={<svg />}
                                />
                            </div>
                            <div className="Playlist__item__number">
                                {index + 1}{' '}
                            </div>
                            <div className="Playlist__item__title">
                                {playlistData.marquee && MARQUEE_ENABLED ? (
                                    <div className="Playlist__item__title__marquee">
                                        <Marquee autoFill={true} speed={20}>
                                            {Array(6)
                                                .fill(0)
                                                .map(() => '\u00A0')}
                                            {'\u00A0'}
                                            {`${song.displayName}`}
                                        </Marquee>
                                    </div>
                                ) : (
                                    song.displayName
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
