import { spotifySearch, addYoutubeToPlaylist, youtubeSearch } from '../../api';
import { useEffect, useState } from 'react';

export const YoutubeModal = ({
    selectedPlaylist: playlist,
    clearModal,
    setLoading,
    isLoading,
    playlists,
}) => {
    const [covers, setCovers] = useState([]);
    const [selectedCover, setSelectedCover] = useState('');

    // const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(playlist);
    const [query, setQuery] = useState('');
    const [error, setError] = useState('');
    const [elements, setElements] = useState([]);
    const [selectedElement, setSelectedElement] = useState(undefined);

    const [addToPlaylistError, setAddToPlaylistError] = useState('');
    const [addedElements, setAddedElements] = useState([]);

    useEffect(() => {
        if (selectedElement?.title) {
            const query = `${selectedElement.title} - ${selectedElement?.artist}`;
            spotifySearch(query, 4).then(({ data }) => {
                setCovers(data.data.map((element) => element.cover));
            });
        }
    }, [selectedElement?.title, selectedElement?.artist]);

    const paste = async () => {
        const text = await navigator.clipboard.readText();

        setQuery(text);
        search(text);
    };

    const search = (query) => {
        setLoading({ title: 'Buscando...' });

        youtubeSearch(query).then(({ data }) => {
            setLoading(undefined);

            if (data.success) {
                const newElements = data.data;

                setError(undefined);
                setElements(data.data);

                if (newElements?.length === 1) {
                    setSelectedElement(newElements[0]);
                }
            } else {
                setError('URL inválida');
            }
        });
    };

    const onSearch = (event) => {
        event?.preventDefault();
        event?.stopPropagation();

        search(query);
    };

    const download = () => {
        if (!selectedElement) {
            setError('No hay elemento seleccionado');
            return;
        }

        setLoading({ title: 'Descargando...' });

        const query = {
            youtubeId: selectedElement.youtubeId,
            playlistId: selectedPlaylist,
            cover: selectedCover,
        };

        addYoutubeToPlaylist(query).then(({ data }) => {
            setLoading(undefined);

            if (
                data.success &&
                data?.data?.youtubeId === selectedElement.youtubeId
            ) {
                setAddedElements((state) => [
                    ...state,
                    selectedElement.youtubeId,
                ]);
                setSelectedElement(undefined);
                setSelectedCover(undefined);
                setCovers([]);
            } else {
                setAddToPlaylistError(data?.data?.message ?? 'Error!!');
            }
        });
    };

    const cleanInput = () => {
        setQuery('');
    };

    return (
        <div className="Modal Youtube">
            <div className="Modal--header">
                <div className="Modal--close" onClick={() => clearModal()}>
                    <i className="fa fa-angle-double-down" />
                </div>
                <div className="Modal--title">
                    <h1>Buscador</h1>
                </div>
            </div>
            <div className={'Modal__container'}>
                <div className={'Youtube__search'}>
                    <label>¿Qué estás buscando?</label>
                    <form onSubmit={onSearch}>
                        <div className={'Youtube__search__input'}>
                            <input
                                type={'text'}
                                value={query}
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                }}
                            />
                        </div>
                    </form>
                    <div class={'Youtube__search__input__actions'}>
                        <div>
                            <div onClick={paste}>
                                <span>pegar</span>
                                <i className={'fa fa-clipboard'}></i>
                            </div>
                            <div onClick={cleanInput}>
                                <span>limpiar</span>
                                <i className={'fa fa-times'}></i>
                            </div>
                        </div>
                        <div>
                            <div onClick={onSearch}>
                                <span>buscar</span>
                                <i className={'fa fa-search'}></i>
                            </div>
                        </div>
                    </div>
                    {error && (
                        <label style={{ color: 'red', marginTop: '0.5em' }}>
                            {error}
                        </label>
                    )}
                </div>

                <div className="Youtube__songs">
                    {elements?.map((element) => {
                        let isSelected =
                            selectedElement?.youtubeId === element.youtubeId;
                        const isAlreadyAdded = addedElements.includes(
                            element.youtubeId
                        );

                        let className = 'Youtube__songs__item';
                        if (isSelected) {
                            className += ' Youtube__songs__item--selected';
                        }
                        if (isAlreadyAdded) {
                            className += ' Youtube__songs__item--added';
                        }

                        return (
                            <div className={className} key={element.youtubeId}>
                                <div
                                    className="Youtube__songs__item__info"
                                    onClick={() => setSelectedElement(element)}
                                >
                                    <div className="Youtube__songs__image">
                                        <img src={element.cover} alt="" />
                                    </div>
                                    <div className="Youtube__songs__info">
                                        <div className="Youtube__songs__title">
                                            {element.title} - {element.artist}
                                        </div>
                                    </div>
                                </div>
                                {isSelected && (
                                    <div className={'Youtube__player'}>
                                        <iframe
                                            width="280"
                                            height="160"
                                            src={`https://www.youtube-nocookie.com/embed/${element.youtubeId}`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                )}
                                {isSelected && (
                                    <div className={'Youtube__covers'}>
                                        {covers?.map((cover, index) => {
                                            const isSelected =
                                                cover === selectedCover;
                                            const className = `Youtube__covers__item ${isSelected ? ' Youtube__covers__item--selected' : ''}`;

                                            return (
                                                <img
                                                    key={`${selectedElement.id}_${cover}_${index}`}
                                                    alt={''}
                                                    className={className}
                                                    src={cover}
                                                    onClick={() =>
                                                        setSelectedCover(cover)
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                                {isSelected && (
                                    <div className={'Youtube__playlist'}>
                                        <select
                                            onChange={(e) =>
                                                setSelectedPlaylist(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="__">
                                                Guardar en la lista...
                                            </option>
                                            {playlists.map((playlist) => (
                                                <option
                                                    value={playlist?.id}
                                                    id={playlist?.id}
                                                    data-status={
                                                        selectedPlaylist ===
                                                        playlist?.id
                                                    }
                                                    selected={
                                                        selectedPlaylist ===
                                                        playlist?.id
                                                    }
                                                >
                                                    {playlist.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {isSelected && selectedPlaylist && (
                                    <div className="Youtube__download">
                                        <div className="Youtube__download__buttons">
                                            <div
                                                onClick={() => {
                                                    setSelectedElement(
                                                        undefined
                                                    );
                                                    setSelectedPlaylist(
                                                        undefined
                                                    );
                                                }}
                                            >
                                                <i
                                                    className={'fa fa-times'}
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                ></i>
                                            </div>
                                            <div onClick={() => download()}>
                                                <i
                                                    className={'fa fa-check'}
                                                    style={{
                                                        color: 'green',
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                        <div className="Youtube__download__result">
                                            {addToPlaylistError}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
